<template>
  <base-submenu>
    <ul>
      <li>
        <router-link to="/documents/documentnumbers"
          >Document Numbers</router-link
        >
      </li>
      <li>
        <router-link to="/documents/disciplines">Disciplines</router-link>
      </li>
      <li>
        <router-link to="/documents/documenttypes">Document Types</router-link>
      </li>
    </ul>
  </base-submenu>
  <router-view></router-view>
</template>

<style scoped>
ul {
  list-style: none;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  margin-left: 169px;
  padding: 0;
}

li {
  margin: 0 1rem;
}

img {
  width: 170px;
}

a {
  text-decoration: none;
  font: inherit;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  display: inline-block;
}

a:hover,
a.active {
  color: #5c5c5c;
}
</style>
